<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="awardees"
              :sort-mapping="sortMapping"
              :title="'awardees'"
              :total="awardees.length"
            >
              <!-- :export-path="'educations/export'" -->
              <template v-slot="{field}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item @click="$router.push({name: 'awarded.awardees-list-edit', params: { id: field.id }})">
                        <i
                          class="me-50"
                          data-feather="user"
                        /> Edit ICREA website information
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </td>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import 'vue2-datepicker/index.css'
import CompleteTable from '../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      filters: [],
      sortedField: 'Position',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
        },
        {
          name: 'Call',
          checked: true,
          order: 2,
        },
        {
          name: 'University',
          checked: true,
          order: 3,
        },
        {
          name: 'Area',
          checked: true,
          order: 4,
        },
        {
          name: 'Year',
          checked: true,
          order: 5,
        },
      ],
      sortMapping: {
        Researcher: 'user.name',
        Call: 'convo.title',
        University: 'host_code',
        Area: 'area_code',
        Year: 'convo.year',
      },
      title: 'Awardees',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      awardees: 'academyAwarded/items',
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'academy-awarded-list')
    await this.$store.dispatch('academyAwarded/fetchByUser')

    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'academy-awarded-list',
      })
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
  },
}
</script>
